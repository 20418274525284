import "./NumInputs.css";
function DateFields({ value, setValue, width }) {
  return (
    <div
      className="flex gap-2"
      style={{
        width: width,
      }}
    >
      <input
        type="text"
        className={`rounded-lg border bg-[#FAFAFA] p-4 ${
          width ? "w-1/2" : "max-w-[85px]"
        }`}
        placeholder="DD"
        pattern="[0-9]{2}"
        maxLength={2}
        value={value.day || ""}
        onChange={(e) => {
          if (e.target.value.length > 0 && isNaN(parseInt(e.target.value)))
            return;
          setValue({ ...value, day: e.target.value });
        }}
        onInput={(e) => {
          const inputValue = e.target.value;
          if (parseInt(inputValue, 10) > 30 || parseInt(inputValue, 10) === 0) {
            e.target.setCustomValidity("Day cannot be greater than 30");
          } else {
            e.target.setCustomValidity("");
          }
        }}
        onInvalid={(e) => {
          e.target.setCustomValidity("Please enter a valid day (01-30)");
        }}
        required
      />
      <input
        type="text"
        className={`rounded-lg border bg-[#FAFAFA] p-4 ${
          width ? "w-1/2" : "max-w-[85px]"
        }`}
        placeholder="MM"
        pattern="[0-9]{2}"
        maxLength={2}
        value={value.month || ""}
        onChange={(e) => {
          if (e.target.value.length > 0 && isNaN(parseInt(e.target.value)))
            return;
          setValue({ ...value, month: e.target.value });
        }}
        onInput={(e) => {
          const inputValue = e.target.value;
          if (parseInt(inputValue, 10) > 13 || parseInt(inputValue, 10) === 0) {
            e.target.setCustomValidity("Month cannot be greater than 13");
          } else {
            e.target.setCustomValidity("");
          }
        }}
        onInvalid={(e) => {
          e.target.setCustomValidity("Please enter a valid month (01-13)");
        }}
        required
      />
      <input
        type="text"
        className={`rounded-lg border bg-[#FAFAFA] p-4 ${
          width ? "w-1/2" : "max-w-[110px]"
        }`}
        placeholder="YYYY"
        pattern="[0-9]{4}"
        maxLength={4}
        value={value.year || ""}
        onChange={(e) => {
          if (e.target.value.length > 0 && isNaN(parseInt(e.target.value)))
            return;
          setValue({ ...value, year: e.target.value });
        }}
        onInput={(e) => {
          const inputValue = e.target.value;
          if (parseInt(inputValue, 10) === 0) {
            e.target.setCustomValidity(
              "Year cannot be 0, please enter a valid year"
            );
          } else {
            e.target.setCustomValidity("");
          }
        }}
        onInvalid={(e) => {
          e.target.setCustomValidity("Please enter a valid year (2017)");
        }}
        required
      />
    </div>
  );
}
export default DateFields;
