import React from "react";

export function useOutsideAlerter(ref, setX) {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setX(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setX]);
}

const DropdownControlled = ({
  button,
  children,
  classNames,
  animation,
  openWrapper,
  setOpenWrapper,
  id = "dropdown",
}) => {
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, setOpenWrapper);

  return (
    <div id={id} ref={wrapperRef} className="absolute flex">
      <div className="flex" onMouseDown={() => setOpenWrapper(!openWrapper)}>
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : "origin-top-right transition-all duration-300 ease-in-out"
        } ${openWrapper ? "scale-200" : "scale-0"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default DropdownControlled;
