import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useRef, useState } from "react";
import { BsStarHalf } from "react-icons/bs";
import InputField from "views/admin/ceo/components/ProjectTextInput";
import StatusDropdown from "components/dropdown/StatusDropdownProduct";
import StatusShow from "components/StatusShow";
import { FaChevronDown } from "react-icons/fa";

function AssignmentSidebarForm({
  handleCreateProject,
  loading,
  error,
  project,
}) {
  const hiddenFileInput = useRef(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [cost, setCost] = useState("");
  const [objective, setObjective] = useState("");
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("not_started");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const formFiles = [...files];

    // formFiles.forEach((file) => {
    //   formData.append("files", file);
    // });

    formData.append("assignment_name", name);
    formData.append("description", description);
    formData.append("start_date", startDate);
    formData.append("end_date", endDate);
    formData.append("budget", cost);
    formData.append("objective", objective);
    formData.append("status", status);

    handleCreateProject(formData);
  };

  useEffect(() => {
    if (project !== null) {
      setName(project.assignment_name || "");
      setDescription(project.description || "");
      setStartDate(project.start_date || "");
      setEndDate(project.end_date || "");
      setCost(project.budget || "");
      setObjective(project.objective || "");
      setStatus(project.status);
    }
    return () => {
      setName("");
      setDescription("");
      setStartDate("");
      setEndDate("");
      setCost("");
      setObjective("");
      setStatus("not_started");
    };
  }, [project]);

  return (
    <div className="mt-12 w-full overflow-hidden px-5 lg:mx-5 lg:mt-0 lg:min-w-[55dvw] lg:p-10 3xl:min-w-[48dvw]">
      <div className="mt-10 mb-5 flex items-center justify-between">
        <h1 className="font-poppins text-2xl font-bold text-[#707070] dark:text-gray-400 lg:text-2xl">
          Add New Individual Assignment
        </h1>
        <button
          className="flex h-fit items-center gap-2 rounded-lg bg-[#485FE5] px-10 py-2 font-poppins text-white disabled:opacity-50"
          type="submit"
          form="productCreateForm"
          disabled={loading}
        >
          {project ? "Update" : "Create"}
        </button>
      </div>

      {error && <Error error={error} />}

      {!error && loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-16 text-[#707070] ">
            <div className="flex justify-between lg:justify-start">
              <div className="flex min-w-fit gap-2 fill-[#E4CCFD]">
                <BsStarHalf color="#D10202" />
                <p>Status</p>
              </div>

              <div className="lg:ml-[27%]">
                {project ? (
                  <StatusShow
                    status={status}
                    extra={"bg-[#FAFAFA] px-4 !pb-0"}
                  />
                ) : (
                  <StatusDropdown
                    pos={"-left-28"}
                    button={
                      <StatusShow
                        status={status}
                        extra={"bg-[#FAFAFA] px-4 !pb-0"}
                      >
                        <FaChevronDown size={10} className="cursor-pointer" />
                      </StatusShow>
                    }
                    onSubmit={(status) => setStatus(status)}
                    status={status}
                  />
                )}
              </div>
            </div>
          </div>

          <form
            id="productCreateForm"
            className="mt-8 mb-5 w-full text-[#707070]"
            onSubmit={handleSubmit}
          >
            <InputField
              title="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={"assignment name"}
              required={true}
            />

            <InputField
              title="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={"assignment description"}
              required={false}
              textArea={true}
              rows={2}
            />
            {/* Dates */}
            <div className="mt-4 flex justify-between">
              <p>
                Start Date <span className="text-red-700">*</span>
              </p>
              <input
                type="date"
                className="rounded-lg bg-[#FAFAFA]  p-4 lg:w-[64%]"
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
                required
              />
            </div>
            <div className="mt-4 flex justify-between">
              <p>
                End Date <span className="text-red-700">*</span>
              </p>
              <input
                type="date"
                className="rounded-lg bg-[#FAFAFA]  p-4 lg:w-[64%]"
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
                required
              />
            </div>

            {/*  */}
            <InputField
              title="Budget"
              type="number"
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              placeholder={"assignment budget"}
              required={false}
            />

            <InputField
              title="Objective"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
              placeholder={"assignment objective"}
              required={false}
              textArea={true}
              rows={2}
            />

            {/* <div className="mt-4 justify-between lg:flex">
              <p className="mb-2">Upload Files</p>
              <input
                type="file"
                ref={hiddenFileInput}
                className="hidden"
                hidden
                multiple
                onChange={(e) => setFiles(e.target.files)}
              />
              {[...files].length > 0 ? (
                <div className="flex h-full w-full flex-col items-center rounded-xl border-[2px] border-dashed border-gray-200 bg-[#FAFAFA] py-3 dark:!border-navy-700 lg:w-[64%] lg:pb-0">
                  {[...files].map((file, index) => (
                    <div
                      key={index}
                      className="mb-1 flex w-full items-center justify-between gap-10 px-4"
                    >
                      <div className="flex items-center gap-1 ">
                        <IoDocuments size={25} className="" />
                        <p className="py-2 text-xl">
                          {file.name.length > 20
                            ? file.name.slice(0, 20) + "..."
                            : file.name}
                        </p>
                      </div>
                      <div
                        className="cursor-pointer py-3"
                        onClick={() => {
                          setFiles((prev) => {
                            const newFiles = [...prev];
                            newFiles.splice(index, 1);
                            return newFiles;
                          });
                          if ([...files].length === 0) {
                            hiddenFileInput.current.value = null;
                          }
                        }}
                      >
                        <BsTrash color="red" size={20} />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 bg-[#FAFAFA] py-3 dark:!border-navy-700 lg:w-[64%] lg:pb-0"
                  onClick={() => hiddenFileInput.current.click()}
                >
                  <MdFileUpload className="text-[80px] text-gray-700 dark:text-white" />
                  <h4 className="text-xl font-bold text-gray-700 dark:text-white">
                    Upload Files
                  </h4>
                  <p className="mt-2 pb-2 text-sm font-medium text-gray-600">
                    PDF, DOCX and XLSX files are allowed
                  </p>
                </div>
              )}
            </div> */}
          </form>
        </>
      )}
    </div>
  );
}

export default AssignmentSidebarForm;
