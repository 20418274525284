import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { statusOptions } from "components/dropdown/StatusDropdown";
import { reverseDateString } from "helpers/utils";
const ComplexTable = (props) => {
  const { columnsData, tableData, setSelectedProject } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto "}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4 font-poppins">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="ml-5 border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                  >
                    <p className="pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "Project Name") {
                      data = (
                        <p className="pl-3 text-sm  text-navy-900 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "No") {
                      data = (
                        <p className="pl-3 text-sm text-navy-900 dark:text-white">
                          {Number(cell.value).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Priority") {
                      data =
                        cell.value === "low" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#ECFDF3] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#12B76A]" />
                            <p className="text-sm text-[#027A48] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "high" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#F15046]" />
                            <p className="text-sm text-[#F15046] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "medium" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#FFB342]" />
                            <p className="text-sm text-[#FFB342] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : null;
                    } else if (cell.column.Header === "Status") {
                      data = (
                        <div className="w-fit rounded-full">
                          <div
                            className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                            style={{
                              background:
                                statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color + "40",
                            }}
                          >
                            <div
                              className="h-2 w-2 rounded-sm "
                              style={{
                                background: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            />
                            <p
                              className="text-sm "
                              style={{
                                color: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            >
                              {cell.value.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Start Date") {
                      data = (
                        <p className="w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "End Date") {
                      data = (
                        <p className="w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "Region") {
                      data = (
                        <p className="w-fit px-4 py-1 text-sm text-navy-900 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <div className="flex items-center gap-2">
                          <Link
                            to={`/higher-official/tasks/${cell.row.original.id}`}
                            className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full"
                          >
                            Open
                          </Link>
                          <button
                            className="max-w-[100px] truncate rounded-lg border border-yellow-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-yellow-500 hover:text-white dark:hover:bg-yellow-500 dark:hover:text-white 2xl:max-w-full"
                            onClick={() =>
                              setSelectedProject(cell.row.original)
                            }
                          >
                            View
                          </button>
                        </div>
                      );
                    }
                    return (
                      <td
                        className="border-b px-1 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
