/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useNavigate, useParams } from "react-router-dom";
import MileComplexTable from "views/admin/tables/components/MileComplexTable";
import { columnsDataMilestone } from "views/admin/ceo/variables/columnsData";
import ListsLayout from "views/admin/ceo/components/ListsLayout";
import SearchIcon from "components/icons/SearchIcon";
import MilestoneModalForm from "views/admin/expert/components/MilestoneModalForm";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import Paginate from "components/pagination";

function TaskDetails() {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const routePage = new URLSearchParams(window.location.search).get("page");
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const userPath = path.includes("ceo")
    ? "ceo"
    : path.includes("team-leader")
    ? "team-leader"
    : path.includes("ministry")
    ? "ministry"
    : path.includes("higher")
    ? "higher-official"
    : "expert";
  const params = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState();
  const [postError, setPostError] = useState(null);
  const link = path.includes("my-task")
    ? `/${userPath}/my-task/${params?.id}`
    : `/${userPath}/tasks/${params?.id}`;
  const [{ data: mileData, loading: mileLoad, error: mileErr }, getMilestones] =
    useAxios(
      {
        url: `/main_app/milestones/filtered-milestones/`,
        params: {
          project_id: params.id,
          page: page || 1,
          search: search,
        },
        method: "GET",
      },
      { manual: true }
    );

  const [{ loading: milePostLoad }, postMile] = useAxios(
    {
      url: "/main_app/milestones/",
      method: "POST",
    },
    { manual: true }
  );

  const handleMileSave = (data) => {
    if (selectedMilestone !== null) {
      postMile({
        url: `/main_app/milestones/${selectedMilestone.id}/`,
        method: "PUT",
        data: {
          project_id: params.id,
          milestone_name: data.milestoneName,
          milestone_description: data.milestoneDescription,
          delivery_date: data.milestoneDeliveryDate,
          status: data.status,
        },
      })
        .then(() => {
          toast("Milestone Updated successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          getMilestones({}, { useCache: false });
          setSelectedMilestone(null);
          setShowModal(false);
        })
        .catch((e) => {
          console.error(e);
          setPostError(e);
          return false;
        });
      return;
    }
    postMile({
      data: {
        project_id: params.id,
        milestone_name: data.milestoneName,
        milestone_description: data.milestoneDescription,
        delivery_date: data.milestoneDeliveryDate,
        status: data.status,
      },
    })
      .then(() => {
        toast("Milestone created successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        getMilestones({}, { useCache: false });
        setShowModal(false);
      })
      .catch((e) => {
        console.error(e);
        setPostError(e);
        return false;
      });
  };

  const handleMileDelete = async (milestoneId) => {
    if (!milestoneId) return;
    try {
      await postMile({
        url: `/main_app/milestones/${milestoneId}/`,
        method: "DELETE",
      }).then(() => {
        toast("Milestone deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        getMilestones({}, { useCache: false });
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (params.id) {
      getMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    getMilestones();
  }, [page]);

  useEffect(() => {
    if (selectedMilestone) {
      console.log("called", selectedMilestone);
      setShowModal(true);
    }
  }, [selectedMilestone]);

  return (
    <ListsLayout breadcrumb={"/tasks/milestones"}>
      {mileLoad && <Loading />}
      {!mileLoad && (
        <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-8 px-8 dark:bg-navy-700">
          {/* Milestones */}
          <div className="mb-5 flex items-start justify-between ">
            <h1 className="text-2xl font-semibold text-black-900">
              Milestones
            </h1>
            <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {path.includes("expert") && (
                <>
                  {showModal && (
                    <MilestoneModalForm
                      data={selectedMilestone}
                      edit={selectedMilestone ? true : false}
                      setEdit={setSelectedMilestone}
                      setOpen={() => {
                        setPostError(null);
                        setShowModal(false);
                      }}
                      error={postError}
                      handleSave={handleMileSave}
                      loading={milePostLoad}
                    />
                  )}
                  <button
                    className="h-[2.6rem] rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
                    onClick={() => {
                      setSelectedMilestone(null);
                      setShowModal(true);
                    }}
                  >
                    Add Milestone
                  </button>
                </>
              )}
            </div>
          </div>
          {mileErr && <Error error={mileErr} />}
          {!mileErr && (
            <MileComplexTable
              tableData={mileData?.results?.milestones || []}
              name={"milestone_name"}
              columnsData={columnsDataMilestone}
              isExpert={path.includes("expert")}
              setSelected={setSelectedMilestone}
              handleDelete={handleMileDelete}
              link={link + "/milestones"}
            />
          )}
          <Paginate
            count={mileData?.count || 0}
            page={page}
            setPage={(p) => {
              navigate(`${link}?page=${p}`);
            }}
            next={() => {
              if (!mileData?.next) {
                return;
              }
              navigate(`${link}?page=${page + 1}`);
            }}
            previous={() => {
              if (!mileData?.previous) {
                return;
              }
              navigate(`${link}?page=${page - 1}`);
            }}
          />
        </div>
      )}
    </ListsLayout>
  );
}

export default TaskDetails;
