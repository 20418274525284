import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function EmailSent() {
  const [resendTime, setResendTime] = useState(0);
  const params = useParams();
  const [{ loading, error }, resendEmail] = useAxios(
    {
      url: "/account/users/resend_activation/",
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    if (resendTime > 0) {
      setTimeout(() => setResendTime(resendTime - 1), 1000);
    }
  }, [resendTime]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="h-full w-full bg-[#F0F2FF] text-center font-poppins text-[#485FE5]">
      <div className="absolute top-64 w-full scale-95 rounded-md bg-white md:left-[35%] lg:m-0 lg:min-h-[300px] lg:w-[550px] lg:scale-100">
        <div className="absolute -top-20 left-[30%] w-fit rounded-full bg-[#F0F2FF] p-4 lg:left-[35%]">
          <div className="w-fit rounded-full bg-white p-10">
            <svg
              viewBox="0 0 38 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 w-10"
            >
              <path
                d="M4.33366 29.6663C3.32533 29.6663 2.46183 29.307 1.74316 28.5883C1.0245 27.8697 0.665773 27.0068 0.666995 25.9997V3.99968C0.666995 2.99134 1.02633 2.12784 1.745 1.40918C2.46366 0.690511 3.32655 0.331789 4.33366 0.333011H33.667C34.6753 0.333011 35.5388 0.692344 36.2575 1.41101C36.9762 2.12968 37.3349 2.99257 37.3337 3.99968V25.9997C37.3337 27.008 36.9743 27.8715 36.2557 28.5902C35.537 29.3088 34.6741 29.6676 33.667 29.6663H4.33366ZM4.33366 7.66634H33.667V3.99968H4.33366V7.66634ZM17.0753 23.3413L27.4337 12.983L24.7753 10.3247L17.0753 18.0247L13.2253 14.1747L10.567 16.833L17.0753 23.3413Z"
                fill="#485FE5"
              />
            </svg>
          </div>
        </div>
        {error && <Error error={error} />}
        {loading && <Loading />}

        {!loading && (
          <div className="mx-auto mt-24 max-w-sm">
            <h1 className="my-4 text-2xl font-bold">
              Please verify your email
            </h1>
            <p className="text-lg font-semibold">
              We sent an email to{" "}
              <span className="underline">{params["*"]}</span>
            </p>
            <p className="my-5 mx-2">
              Just click on the link in that email to complete your signup. If
              you don't see it, you may need to check your spam folder.
            </p>
            <p className={`mx-auto mb-5 w-fit font-semibold underline `}>
              Still can't find the email?
            </p>

            <button
              className={`mb-10 rounded-md py-3 px-20 font-semibold text-white ${
                resendTime > 0
                  ? "bg-gray-300"
                  : "bg-[#485FE5] hover:bg-[#3B4E9A]"
              }`}
              onClick={() => {
                resendEmail({ data: { email: params["*"] } });
                setResendTime(60);
              }}
              disabled={resendTime > 0}
            >
              <p>
                {resendTime > 0 ? `Resend in ${resendTime}s` : "Send Again"}
              </p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailSent;
