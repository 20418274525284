/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useNavigate, useParams } from "react-router-dom";
import { columnsDataActivities } from "views/admin/ceo/variables/columnsData";
import ListsLayout from "views/admin/ceo/components/ListsLayout";
import SearchIcon from "components/icons/SearchIcon";
import ActivityModalForm from "views/admin/expert/components/ActivityModalForm";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import MileComplexTable from "views/admin/tables/components/MileComplexTable";
import Paginate from "components/pagination";

function ActivitiesList() {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const mileName = new URLSearchParams(window.location.search).get("milename");
  const routePage = new URLSearchParams(window.location.search).get("page");
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);

  const userPath = path.includes("ceo")
    ? "ceo"
    : path.includes("team-leader")
    ? "team-leader"
    : path.includes("ministry")
    ? "ministry"
    : path.includes("higher")
    ? "higher-official"
    : "expert";
  const params = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState();
  const [error, setError] = useState();
  const link = path.includes("my-task")
    ? `/${userPath}/my-task/${params?.id}/milestones/${params?.mileId}`
    : `/${userPath}/tasks/${params?.id}/milestones/${params?.mileId}`;

  const [{ data: actData, loading: actLoad, error: actErr }, getMilestones] =
    useAxios(
      {
        url: `/main_app/activities/filtered_activity/`,
        params: {
          project_milestone: params.mileId,
          page: page || 1,
          search: search,
        },
        method: "GET",
      },
      { manual: true }
    );

  const [{ loading: actPostLoad }, postActivity] = useAxios(
    {
      url: "/main_app/activities/",
      method: "POST",
    },
    { manual: true }
  );

  const handleActSave = (data) => {
    const formdata = {
      activity_name: data.activityName,
      activity_description: data.activityDesc,
      activity_budget: data.activityBudget,
      status: data.activityStatus,
      start_date: data.activityStartDate,
      end_date: data.activityEndDate,
      project_milestone: params.mileId,
      departments: data.activityDepartments,
    };
    if (selectedActivity !== null) {
      postActivity({
        url: `/main_app/activities/${selectedActivity.id}/`,
        method: "PUT",
        data: formdata,
      })
        .then(() => {
          toast("Activity updated successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          getMilestones({}, { useCache: false });
          setSelectedActivity(null);
          setShowModal(false);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        });
    } else {
      postActivity({
        data: formdata,
      })
        .then(() => {
          toast("Activity created successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          getMilestones({}, { useCache: false });
          setShowModal(false);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        });
    }
  };

  const handleActDelete = (id) => {
    if (!id) return;
    postActivity({
      url: `/main_app/activities/${id}/`,
      method: "DELETE",
    })
      .then(() => {
        toast("Activity deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        getMilestones({}, { useCache: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (params.id) {
      getMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    getMilestones();
  }, [page]);

  useEffect(() => {
    if (selectedActivity) {
      setShowModal(true);
      setError(null);
    }
  }, [selectedActivity]);

  return (
    <ListsLayout
      title={mileName || "Activities List"}
      breadcrumb={`/tasks/milestones/activities`}
    >
      {actLoad && <Loading />}
      {!actLoad && (
        <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-8 px-8 dark:bg-navy-700">
          {/* Activities */}
          <div className="mb-5 flex items-start justify-between ">
            <h1 className="text-2xl font-semibold text-black-900">
              Activities
            </h1>
            <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {path.includes("expert") && (
                <>
                  {showModal && (
                    <ActivityModalForm
                      data={selectedActivity}
                      edit={selectedActivity}
                      setEdit={setSelectedActivity}
                      setOpen={(bool) => {
                        setSelectedActivity(null);
                        setShowModal(bool);
                      }}
                      error={error}
                      handleSave={handleActSave}
                      loading={actPostLoad}
                    />
                  )}
                  <button
                    className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
                    onClick={() => {
                      setSelectedActivity(null);
                      setError(null);
                      setShowModal(true);
                    }}
                  >
                    Add Activity
                  </button>
                </>
              )}
            </div>
          </div>
          {actErr && <Error error={actErr} />}

          {!actErr && (
            <MileComplexTable
              tableData={actData?.results?.activities || []}
              name={"activity_name"}
              columnsData={columnsDataActivities}
              isExpert={path.includes("expert")}
              setSelected={setSelectedActivity}
              handleDelete={handleActDelete}
              link={link + "/activities"}
            />
          )}
          <Paginate
            count={actData?.count || 0}
            page={page}
            setPage={(p) => {
              navigate(`${link}?milename=${mileName}&page=${p}`);
            }}
            next={() => {
              if (!actData?.next) {
                return;
              }
              navigate(`${link}?milename=${mileName}&page=${page + 1}`);
            }}
            previous={() => {
              if (!actData?.previous) {
                return;
              }
              navigate(`${link}?milename=${mileName}&page=${page - 1}`);
            }}
          />
        </div>
      )}
    </ListsLayout>
  );
}

export default ActivitiesList;
