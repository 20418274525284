export const columnsDataMilestone = [
  {
    Header: "NO",
    accessor: "id",
    width: 20,
  },
  {
    Header: "Name",
    accessor: "milestone_name",
  },
  {
    Header: "Delivery Date",
    accessor: "delivery_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
    width: 20,
  },
];

export const columnsDataActivities = [
  {
    Header: "NO",
    accessor: "id",
    width: 20,
  },
  {
    Header: "Name",
    accessor: "activity_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
    width: 20,
  },
];

export const columnsDataDeliverables = [
  {
    Header: "NO",
    accessor: "id",
    width: 20,
  },
  {
    Header: "Name",
    accessor: "deliverable_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
    width: 140,
  },
];
