/* eslint-disable no-empty-pattern */
import useAxios from "axios-hooks";
import Dropdown from "components/dropdown";
import StatusDropdownAssAct from "components/dropdown/StatusDropdownAssAct";
import AssActivityViewModal from "components/popover/AssActivityViewModal";
import StatusShow from "components/StatusShow";
import { stringifyError } from "helpers/generateFuns";
import { useState } from "react";
import toast from "react-hot-toast";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoAlert, IoCheckmark } from "react-icons/io5";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import AssSubActivityModal from "views/admin/ministry/components/AssSubActivityModal";

function AssSubActivityRow({
  subActivity,
  refetch,
  handleRemoveActivity,
  isUserAssigned,
  padding = "40",
}) {
  const [update, setUpdate] = useState(false);
  const [view, setView] = useState(false);
  const [viewForm, setViewForm] = useState(false);
  const [{}, updateActivity] = useAxios(
    {
      url: `/ministry_tasks/assignment-activities/${subActivity.id}`,
      method: "DELETE",
    },
    { manual: true }
  );

  const updateAct = async (data, config) => {
    try {
      await updateActivity({
        url: config.url,
        method: "PUT",
        headers: config.headers || {
          "Content-Type": "application/json",
        },
        data: data,
      }).then(async () => {
        toast("Sub Activity updated successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        refetch();
      });
    } catch (error) {
      const errorMsg = stringifyError(error);
      toast(
        `An error occurred 
        ${errorMsg}`,
        {
          icon: <IoAlert size={30} />,
          style: { color: "red", alignItems: "start" },
          duration: 8000,
        }
      );
    }
  };

  const handleStatusChange = async (status) => {
    updateAct(
      {
        ...subActivity,
        status: status.status,
        ongoing_status_memo: status.memo,
      },
      { url: `/ministry_tasks/assignment-activities/${subActivity.id}/` }
    );
  };

  return (
    <tr
      className={`table-row h-14 bg-gray-50 dark:bg-navy-900
        `}
    >
      <td colSpan={2}>
        <div
          className={`relative flex max-w-[300px]`}
          style={{ paddingLeft: `${padding}px` }}
        >
          <div className="ml-4 flex w-fit items-center justify-between gap-3 text-start">
            <p className="dark:text-white">
              {subActivity?.assignment_activity_name}
            </p>
          </div>
        </div>
      </td>

      <td>
        <div className="flex justify-center">
          <p className="w-[110px] truncate text-center dark:text-white">
            {subActivity?.assignment_activity_description || "N/A"}
          </p>
        </div>
      </td>
      {isUserAssigned && (
        <td>
          {viewForm && (
            <AssSubActivityModal
              activity={subActivity}
              key={subActivity?.id}
              setOpen={setViewForm}
              refetch={refetch}
              update={update}
            />
          )}
        </td>
      )}
      <td className="flex justify-center">
        {isUserAssigned ? (
          <StatusDropdownAssAct
            pos={"-left-28"}
            button={
              <StatusShow status={subActivity?.status}>
                <FaChevronDown size={10} className="cursor-pointer" />
              </StatusShow>
            }
            onSubmit={(value) => handleStatusChange(value)}
          />
        ) : (
          <StatusShow status={subActivity?.status} />
        )}
      </td>

      <td>
        <div className="flex w-full items-center justify-center gap-1">
          <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
            {subActivity?.start_date}
          </p>
          <BsChevronRight size={10} />
          <p className="rounded-md bg-[#464F60]/10 p-1 px-2 dark:bg-[#E9EDF5] dark:text-gray-800">
            {subActivity?.end_date}
          </p>
        </div>
      </td>

      <td>
        {view && (
          <AssActivityViewModal
            id={"activity" + subActivity.id}
            data={subActivity}
            setOpen={setView}
          />
        )}
        <DeleteModal
          id={"deleteSubActivity" + subActivity.id}
          handleConfirm={() => {
            handleRemoveActivity(subActivity.id);
            window.document
              .getElementById(`deleteSubActivity${subActivity.id}`)
              .classList.replace("flex", "hidden");
          }}
          handleCancel={() => {
            window.document
              .getElementById(`deleteSubActivity${subActivity.id}`)
              .classList.replace("flex", "hidden");
          }}
        />
        <Dropdown
          button={<HiDotsVertical className="cursor-pointer" />}
          id={`dropdown${subActivity.id}`}
          classNames={"top-5 min-w-max -left-24"}
          children={
            <div className="flex flex-col items-start gap-2 rounded-md bg-white px-1 py-5 dark:bg-navy-900">
              <p
                className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                onClick={() => {
                  setView(true);
                }}
              >
                View
              </p>
              {isUserAssigned && (
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    setUpdate(true);
                    setViewForm(true);
                  }}
                >
                  Edit
                </p>
              )}
              {isUserAssigned && (
                <p
                  className=" w-full cursor-pointer rounded-md px-8 py-2 hover:bg-gray-50 dark:hover:bg-navy-700"
                  onClick={() => {
                    window.document
                      .getElementById(`deleteSubActivity${subActivity.id}`)
                      .classList.replace("hidden", "flex");
                  }}
                >
                  Delete
                </p>
              )}
            </div>
          }
        />
      </td>
    </tr>
  );
}
export default AssSubActivityRow;
