import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { statusOptions } from "components/dropdown/StatusDropdown";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/dropdown/ShadcnDropdown";
import { reverseDateString } from "helpers/utils";

const ComplexTable = (props) => {
  const { columnsData, tableData, setSelectedProject, showForm, link } = props;
  const navigate = useNavigate();
  const path = window.location.pathname;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto shadow"}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4 font-poppins">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="ml-5 border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                  >
                    <p className="pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "Task Name") {
                      data = (
                        <p className="max-w-[130px] truncate pl-3 pr-2 text-sm text-navy-900 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "NO") {
                      data = (
                        <p className="pl-3 text-sm text-navy-900 dark:text-white">
                          {Number(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Priority") {
                      data =
                        cell.value === "low" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#ECFDF3] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#12B76A]" />
                            <p className="text-sm text-[#027A48] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "high" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#F15046]" />
                            <p className="text-sm text-[#F15046] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "medium" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#FFB342]" />
                            <p className="text-sm text-[#FFB342] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : cell.value === "pending" ? (
                          <div className="flex w-fit items-center gap-2 rounded-2xl bg-[#FFF2EA] p-0.5 px-3">
                            <div className="h-2 w-2 rounded-lg bg-[#FFB342] capitalize" />
                            <p className="text-sm text-[#FFB342] ">
                              {cell.value}
                            </p>
                          </div>
                        ) : null;
                    } else if (cell.column.Header === "Status") {
                      data = (
                        <div className="w-fit rounded-full">
                          <div
                            className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                            style={{
                              background:
                                statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color + "40",
                            }}
                          >
                            <div
                              className="h-2 w-2 rounded-sm "
                              style={{
                                background: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            />
                            <p
                              className="text-sm "
                              style={{
                                color: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            >
                              {cell.value.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Start Date") {
                      data = (
                        <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "End Date") {
                      data = (
                        <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "Action") {
                      data =
                        cell.value !== 1 ? (
                          <div className="flex items-center gap-3 text-gray-800 dark:text-white">
                            <DeleteModal
                              id={"project" + cell.row.original.id}
                              handleConfirm={() => {
                                props.handleDeleteProject(cell.row.original.id);
                                window.document
                                  .getElementById(
                                    "project" + cell.row.original.id
                                  )
                                  .classList.replace("flex", "hidden");
                              }}
                              handleCancel={() => {
                                window.document
                                  .getElementById(
                                    "project" + cell.row.original.id
                                  )
                                  .classList.replace("flex", "hidden");
                              }}
                            />
                            <button
                              className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full"
                              onClick={() => {
                                link
                                  ? navigate(`${link}/${cell.row.original.id}`)
                                  : navigate(
                                      path.includes("my-task")
                                        ? `/ceo/my-task/${cell.row.original.id}`
                                        : `/ceo/tasks/${cell.row.original.id}`
                                    );
                              }}
                            >
                              Open
                            </button>

                            <DropdownMenu>
                              <DropdownMenuTrigger className="outline-none focus:outline-none">
                                <BsThreeDotsVertical className="cursor-pointer" />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                  onClick={() => {
                                    setSelectedProject(cell.row.original);
                                  }}
                                >
                                  View
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                  onClick={() => {
                                    setSelectedProject(cell.row.original);
                                    showForm();
                                  }}
                                >
                                  Edit
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                  onClick={() => {
                                    window.document
                                      .getElementById(
                                        "project" + cell.row.original.id
                                      )
                                      .classList.replace("hidden", "flex");
                                  }}
                                >
                                  Delete
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        ) : (
                          <div className="flex items-center gap-2">
                            <Link
                              to={
                                link
                                  ? `${link}/${cell.row.original.id}`
                                  : path.includes("my-task")
                                  ? `/ceo/my-task/${cell.row.original.id}`
                                  : `/ceo/tasks/${cell.row.original.id}`
                              }
                              className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full"
                            >
                              Open
                            </Link>
                            <button
                              className="max-w-[100px] truncate rounded-lg border border-yellow-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-yellow-500 hover:text-white dark:hover:bg-yellow-500 dark:hover:text-white 2xl:max-w-full"
                              onClick={() =>
                                setSelectedProject(cell.row.original)
                              }
                            >
                              View
                            </button>
                          </div>
                        );
                    }
                    return (
                      <td
                        className="border-b px-0 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
