import Checkbox from "components/checkbox";
import Error from "components/error";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import avatar from "assets/img/avatars/dummy.png";

function ExpertsDropdown({
  experts,
  selectedExpert,
  setSelectedExpert,
  loading,
  error,
  assignExpert,
  assignedTo,
}) {
  const [filteredExperts, setFilteredExperts] = useState(experts);

  const assignedExpert = experts.find(
    (expert) => expert.profile?.user === assignedTo
  );

  const handleSearch = (e) => {
    const value = e.target.value;

    if (value !== "") {
      const filtered = experts.filter(
        (expert) =>
          expert?.profile?.first_name
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          expert?.profile?.last_name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredExperts(filtered);
      return;
    }
    setFilteredExperts(experts);
  };

  useEffect(() => {
    setFilteredExperts(experts);
  }, [experts]);

  useEffect(() => {
    if (assignedTo) {
      const expert = experts.find(
        (expert) => expert.profile?.user === assignedTo
      );
      setSelectedExpert(expert);
    }
  }, [assignedTo, experts, setSelectedExpert]);

  return (
    <div className="rounded-lg bg-white px-6 py-4 shadow-xl dark:bg-navy-700 dark:text-white dark:shadow-xl">
      <h1>Assigned Expert</h1>
      {assignedExpert?.profile && (
        <div className="my-3 flex items-center gap-3 rounded-lg px-3 py-1.5">
          <Checkbox
            value={assignedExpert?.profile?.user}
            checked={
              assignedExpert?.profile?.user === selectedExpert?.profile?.user
            }
            onChange={() => setSelectedExpert(assignedExpert)}
            extra={`!rounded-md`}
          />
          <img
            src={assignedExpert?.profile?.file || avatar}
            alt="profile"
            className="h-5 w-5 rounded-full"
          />
          <p className="text-sm text-gray-800 dark:text-white">
            {assignedExpert?.profile?.first_name}{" "}
            {assignedExpert?.profile?.last_name}
          </p>
        </div>
      )}
      <input
        type="text"
        placeholder="Search"
        className="h-9 w-full rounded-lg border border-gray-200 px-3 text-sm text-gray-900 placeholder:text-gray-900 dark:bg-navy-700 dark:text-white dark:placeholder-gray-400"
        onChange={handleSearch}
      />
      <div className="mt-2 flex justify-end gap-2">
        <button
          className="rounded-lg bg-[#5E5ADB] py-2 px-8 text-white hover:bg-navy-500"
          onClick={assignExpert}
        >
          Assign
        </button>
      </div>
      {loading && <Loading />}
      {error && <Error error={error} />}
      {!loading &&
        filteredExperts?.map((expert, index) => (
          <div
            key={index}
            className="my-3 flex items-center gap-3 rounded-lg px-3 py-1.5"
          >
            <Checkbox
              value={expert?.profile?.user}
              checked={
                expert?.profile &&
                selectedExpert?.profile?.user === expert?.profile?.user
              }
              onChange={() => setSelectedExpert(expert)}
              extra={`!rounded-md`}
            />
            <img
              src={expert?.profile?.file || avatar}
              alt="profile"
              className="h-5 w-5 rounded-full"
            />
            {expert?.profile ? (
              <p className="text-sm text-gray-800 dark:text-white">
                {expert?.profile?.first_name} {expert?.profile?.last_name}
              </p>
            ) : (
              <p className="text-sm text-gray-800 dark:text-white">
                {expert.email}
              </p>
            )}
          </div>
        ))}
    </div>
  );
}
export default ExpertsDropdown;
