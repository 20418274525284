export const checkDate = (startDate, endDate) => {
  if (
    !startDate.day ||
    !startDate.month ||
    !startDate.year ||
    !endDate.day ||
    !endDate.month ||
    !endDate.year
  )
    return true;
  const start = new Date(
    `${startDate.year}-${startDate.month}-${startDate.day}`
  );
  const end = new Date(`${endDate.year}-${endDate.month}-${endDate.day}`);
  return start <= end;
};

export const reverseDateString = (date) => {
  return date.split("-").reverse().join("-");
};
