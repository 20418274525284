import useAxios from "axios-hooks";
import StatusBadge from "components/Badge/StatusBadge";
import Dropdown from "components/dropdown";
import Error from "components/error";
import Loading from "components/loading";
import ProjectTitle from "components/ProjectTitle";
import { CeoRouteNameContext } from "layouts/ceo";
import { ExpertRouteNameContext } from "layouts/expert";
import { HoRouteNameContext } from "layouts/HO";
import { ministryRouteNameContext } from "layouts/ministry";
import { TLRouteNameContext } from "layouts/teamLeader";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoCheckmark, IoPersonAdd } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import ExpertsDropdown from "views/admin/team-leader/components/ExpertsDropdown";

function ListsLayout({ children, title, breadcrumb }) {
  const navigate = useNavigate();
  const path = window.location.href;
  const params = useParams();
  // const [showModal, setShowModal] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState();

  const { setCurrentRoute, currentRoute } = useContext(
    path.includes("ceo")
      ? CeoRouteNameContext
      : path.includes("team-leader")
      ? TLRouteNameContext
      : path.includes("ministry")
      ? ministryRouteNameContext
      : path.includes("higher")
      ? HoRouteNameContext
      : ExpertRouteNameContext
  );
  const [{ data: project, loading: prLoad, error: prErr }, getProject] =
    useAxios(
      {
        url: `/main_app/projects/${params.id}/`,
        method: "GET",
      },
      { useCache: true }
    );

  const [{ data: experts, loading: expertLoad, error: expertsError }] =
    useAxios(
      {
        url: `/account/expert-users/`,
        method: "GET",
      },
      { useCache: false, manual: path.includes("team-leader") ? false : true }
    );

  const [{ loading: putLoading, error: putError }, assignExpert] = useAxios(
    {
      url: `/main_app/projects/${params.id}/assign/`,
      method: "PUT",
    },
    { manual: true }
  );

  const handleAssignExpert = async () => {
    try {
      await assignExpert({
        url: `/main_app/projects/${params.id}/assign/`,
        method: "PUT",
        data: {
          assigned_to: selectedExpert?.profile?.user,
        },
      });
      toast.success("Expert assigned successfully", {
        icon: <IoCheckmark />,
        style: { color: "green" },
      });
      getProject();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (project && currentRoute !== project?.project_name) {
      setCurrentRoute(`${project?.project_name}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  return (
    <div className="fill-blueSecondary font-poppins">
      {!prErr && <Error error={prErr} />}
      {prLoad && <Loading />}
      {!prLoad && (
        <ProjectTitle
          title={title || project?.project_name}
          path={breadcrumb}
          onBackClicked={() => {
            path.includes("page")
              ? navigate(-2, { replace: true })
              : navigate(-1, { replace: true });
          }}
        />
      )}
      {path.includes("team-leader") && (
        <div className="flex w-full items-start justify-between">
          <div>
            {!project?.execution_plan_status === "pending" && (
              <StatusBadge status={project?.execution_plan_status} />
            )}
          </div>
          {project?.execution_plan_status === "pending" && (
            <Dropdown
              button={
                <button className="flex items-center gap-2 rounded-lg bg-[#485FE5] px-10 py-3 text-sm text-white">
                  <IoPersonAdd size={25} />
                  Assign Expert / Coordinator
                </button>
              }
              classNames={
                "top-16 -left-16 min-w-[250px] overflow-y-scroll max-h-[400px]"
              }
              animation={"origin-top transition-all duration-300 ease-in-out"}
              id="expertsDropdown"
              children={
                <div className="min-w-[250px] bg-white">
                  {expertLoad && <Loading />}
                  {expertsError && <Error error={expertsError} />}
                  {experts && (
                    <ExpertsDropdown
                      assignedTo={project?.assigned_to}
                      experts={experts}
                      selectedExpert={selectedExpert}
                      setSelectedExpert={setSelectedExpert}
                      loading={putLoading}
                      error={putError}
                      assignExpert={handleAssignExpert}
                    />
                  )}
                </div>
              }
            />
          )}
        </div>
      )}
      {!prLoad && children}
    </div>
  );
}
export default ListsLayout;
