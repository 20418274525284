import React, { createContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Footer from "components/footer/Footer";
import ProjectsList from "views/admin/ministry/TasksList";
import ProjectDashboard from "views/admin/ceo/ProjectDashboard";
import { ministryRoutes } from "routes";
import { useWindowSize } from "@uidotdev/usehooks";
import ProjectsModal from "components/popover/ProjectsModal";
import Sidebar from "components/sidebar";
import AssignmentDetails from "views/admin/ministry/AssignmentDetails";
import TaskDetails from "views/admin/ceo/TaskDetails";
import ActivitiesList from "views/admin/ceo/ActivitiesList";
import DeliverableDetails from "views/admin/ceo/DeliverableDetails";
import DeliverablesList from "views/admin/ceo/DeliverablesList";
import AssignmentForm from "views/admin/ministry/AssignmentForm";

export const ministryRouteNameContext = createContext(null);

export default function AdminMinistry(props) {
  const { ...rest } = props;
  const size = useWindowSize();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState("Ministry Dashboard");

  const ministryHiddenRoutes = [
    ...ministryRoutes,
    {
      name: "Project",
      title: "Projects List",
      layout: "/ministry",
      path: "projects/*",
      component: <ProjectsList />,
    },
    {
      name: "Project Details",
      layout: "/ministry",
      path: "tasks/:id",
      component: <TaskDetails />,
    },
    {
      name: "Activities List",
      layout: "/ministry",
      path: "tasks/:id/milestones/:mileId",
      component: <ActivitiesList />,
    },
    {
      name: "Deliverables List",
      layout: "/ministry",
      path: "tasks/:id/milestones/:mileId/activities/:actId",
      component: <DeliverablesList />,
    },
    {
      name: "Deliverables Details",
      layout: "/ministry",
      path: "tasks/:id/milestones/:mileId/activities/:actId/deliverables/:delId",
      component: <DeliverableDetails />,
    },
    {
      name: "Project Details",
      layout: "/ministry",
      path: "assignments/:id",
      component: <AssignmentDetails />,
    },
    {
      name: "Project Dashboard",
      layout: "/ministry",
      path: "projects/create",
      component: <ProjectsList />,
    },
    {
      name: "Project 1 dashboard",
      layout: "/ministry",
      path: "projects/:id/dashboard",
      component: <ProjectDashboard />,
    },
    {
      name: "Individual Assignment Form",
      layout: "/ministry",
      path: "assignments/create",
      component: <AssignmentForm />,
    },
  ];

  React.useEffect(() => {
    if (size?.width < 1400) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [size]);

  React.useEffect(() => {
    getActiveRoute(ministryHiddenRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/ministry") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <ministryRouteNameContext.Provider
      value={{ setCurrentRoute, currentRoute }}
    >
      <div className="flex w-full">
        <ProjectsModal
          id={"ProjectsModalCeo"}
          link={"ceo"}
          showModal={showModal}
          setShowModal={setShowModal}
        />

        <Sidebar
          open={open}
          onClose={() => setOpen(!open)}
          setShowModal={setShowModal}
          cRoutes={ministryRoutes}
        />
        {/* Navbar & Main Content */}
        <div className=" w-full bg-white dark:!bg-navy-900">
          {/* Main Content */}
          <main
            className={`mx-[12px] flex-none transition-all md:pr-2 ${
              open ? "xl:ml-[313px]" : ""
            }`}
          >
            {/* Routes */}
            <div className="">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                isSidebarOpen={open}
                logoText={"MoLS Project Management System"}
                brandText={currentRoute}
                secondary={getActiveNavbar(ministryHiddenRoutes)}
                {...rest}
              />
              <div className="mx-auto mb-auto  min-h-[84vh] px-2 pt-3 md:pr-2">
                <Routes>
                  {getRoutes(ministryHiddenRoutes)}

                  <Route
                    path="/"
                    element={<Navigate to="/ministry/home" replace />}
                  />
                </Routes>
              </div>
              <div className="px-3">
                <Footer />
              </div>
            </div>
          </main>
        </div>
      </div>
    </ministryRouteNameContext.Provider>
  );
}
