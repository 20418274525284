function DashboardPBI() {
  return (
    <iframe
      title="Task"
      className="min-h-[600px] w-full"
      src="https://app.powerbi.com/view?r=eyJrIjoiNTU3N2ZkMmEtMzJhZS00YjcxLWE1YTktY2ZlMTBmZjBlZjdmIiwidCI6IjdlYzdjZGQ2LTI2Y2MtNGIwMS1iOTA3LTI5MzllZGI5Y2Y1MyJ9"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  );
}
export default DashboardPBI;
