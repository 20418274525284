import AllProjects from "components/icons/AllProjects";
import PendingProjects from "components/icons/PendingProjects";
import ComplexTable from "./components/ComplexTable";
import { useEffect, useState } from "react";
import RoleModalForm from "views/admin/default/components/RoleModalForm";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";

export const usersColumns = [
  {
    Header: "NO",
    accessor: "no",
  },
  {
    Header: "First Name",
    accessor: "profile.first_name",
  },
  {
    Header: "Last Name",
    accessor: "profile.last_name",
  },
  {
    Header: "ROLE",
    accessor: "roles[0].role.name",
  },
  {
    Header: "STATUS",
    accessor: "is_active",
  },

  {
    Header: "ACTION",
    accessor: "action",
  },
];

function UsersList() {
  const routePage = new URLSearchParams(window.location.search).get("page");
  const [activeTab, setActiveTab] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);

  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: "/account/user_list",
      params: { page: page || 1 },
    },
    {
      useCache: false,
    }
  );

  const tabs = [
    { title: "All Users List", icon: <AllProjects /> },
    {
      title: "Active",
      icon: <PendingProjects />,
    },
    { title: "Inactive", icon: <PendingProjects /> },
  ];

  useEffect(() => {
    if (data) {
      setFilteredData(data.results);
      if (activeTab === 0) {
        setFilteredData(data.results);
      } else if (activeTab === 1) {
        setFilteredData(
          data.results.filter((item) => item["is_active"] === true)
        );
      } else if (activeTab === 2) {
        setFilteredData(
          data.results.filter((item) => item["is_active"] === false)
        );
      }
    }
  }, [data, activeTab]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refetch({ params: { search: search } });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  return (
    <div className="mt-5">
      <RoleModalForm
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        refetch={refetch}
      />

      <div className="mb-3 flex flex-col-reverse items-end justify-between lg:flex-row lg:items-center">
        <div className="my-3 flex w-full border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400 lg:w-fit">
          <ul className="-mb-px w-full lg:flex">
            {tabs.map((item, index) => (
              <li
                className="mr-10 flex w-full items-center gap-2 lg:w-fit"
                key={index}
              >
                <p
                  className={`mt-3 flex w-full cursor-pointer items-center gap-3 rounded-t-lg p-4  hover:border-navy-300 hover:text-gray-600 dark:hover:text-gray-300  lg:mt-0 ${
                    activeTab === index
                      ? "border-b-2 border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "fill-[#84818A] dark:border-none"
                  }`}
                  onClick={() => setActiveTab(index)}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-full lg:w-1/3">
          <div className="flex h-14 items-center justify-between gap-3 rounded-lg border border-gray-200 px-3 text-gray-900 placeholder:text-gray-900 ">
            <input
              id="AdminUserSearch"
              type="text"
              placeholder="Search"
              className="w-full flex-grow rounded-lg border-none px-3 text-gray-900 placeholder:text-gray-900 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400 lg:flex-grow-0"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
          {search && search.length < 3 && (
            <p className="ml-2 text-red-500">Must be at least 3 characters</p>
          )}
        </div>
      </div>
      {error && <Error error={error} />}
      {loading && <Loading />}
      {!loading && data?.results?.length > 0 && (
        <>
          <ComplexTable
            columnsData={usersColumns}
            tableData={filteredData}
            setUser={(user) => {
              setSelectedUser(user);
              window.document
                .getElementById("RoleModalForm")
                .classList.replace("hidden", "flex");
            }}
          />
          <Paginate
            count={data?.count}
            page={page}
            setPage={(p) => {
              // navigate(`/ceo/projects?page=${p}`);
              setPage(p);
            }}
            next={() => {
              if (!data?.next) {
                return;
              }
              // navigate(`/ceo/projects?page=${page + 1}`);
              setPage(page + 1);
            }}
            previous={() => {
              if (!data?.previous) {
                return;
              }
              // navigate(`/ceo/projects?page=${page - 1}`);
              setPage(page - 1);
            }}
          />
        </>
      )}
    </div>
  );
}

export default UsersList;
