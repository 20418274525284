function Error({ error, ref }) {
  return (
    <div
      ref={ref || null}
      className="relative w-full text-red-700"
      role="alert"
    >
      {error?.response?.status !== 500 && error?.response?.data[0] !== "<" ? (
        error?.response?.data && (
          <pre className="max-w-full rounded bg-red-200 p-2 xl:max-w-4xl">
            <strong className="mr-3 font-bold">Error!</strong>
            {JSON.stringify(error.response.data, null, 2).replace(
              /[[\]{}""," "]+/g,
              " "
            )}
          </pre>
        )
      ) : (
        <pre className="mt-2 rounded bg-red-200 p-2 text-sm">
          Server error. Please try again later or contact the administrator.
          {"\n"}
          {error?.response?.config?.url}
        </pre>
      )}
    </div>
  );
}
export default Error;
