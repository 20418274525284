import DateFields from "components/fields/DateFields";
import { useState } from "react";

function AssignmentForm() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [objective, setObjective] = useState("");
  const [budget, setBudget] = useState("");
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [err, setErr] = useState("");

  return (
    <div className="max-w-[1200px] p-6">
      {/* <h1 className="mb-6 text-2xl font-semibold text-gray-700">
        Assigned Projects
      </h1> */}

      {/* Tabs */}
      <div className="mb-8 w-[60%] rounded-2xl border bg-white">
        <div className="flex justify-between">
          <div className="border-b-1 border-blue-600 py-2 px-4 text-blue-600">
            <p className="w-fit rounded-lg bg-blue-50 px-12 py-1 text-sm">
              All Assignment
            </p>
          </div>
          <div className="border-b-1 border-blue-600 py-2 px-4 text-gray-700">
            <p className="w-fit rounded-lg px-12 py-1 text-sm">
              Executing Body
            </p>
          </div>
          <div className="border-b-1 border-blue-600 py-2 px-4 text-gray-700">
            <p className="w-fit rounded-lg  px-12 py-1 text-sm">Milestone</p>
          </div>
        </div>
      </div>

      {/* Form Section */}
      <div className="space-y-6">
        <h2 className="text-xl font-semibold">
          Create an individual assignment
        </h2>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Name Input */}
          <div className="space-y-2">
            <label className="text-sm text-gray-700">Name</label>
            <input
              type="text"
              placeholder="Write the name of an assignment"
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          {/* Start Date */}
          <div className="space-y-2">
            <label className="text-sm text-gray-700">Start date</label>
            <DateFields
              value={startDate}
              onChange={setStartDate}
              width={"100%"}
            />
          </div>

          {/* Description Select */}
          <div className="space-y-1">
            <label className="text-sm text-gray-700">Description</label>
            <textarea
              placeholder="Write the description of an assignment"
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* End Date */}
          <div className="space-y-2">
            <label className="text-sm text-gray-700">End date</label>
            <DateFields value={endDate} onChange={setEndDate} width={"100%"} />
          </div>

          {/* Budget */}
          <div className="space-y-2">
            <label className="text-sm text-gray-700">Budget</label>
            <input
              type="number"
              placeholder="Write a budget"
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
            />
            {false && (
              <div className="flex items-center gap-2 text-sm text-red-500">
                <svg
                  className="h-4 w-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>only write this in number, in currency</span>
              </div>
            )}
          </div>

          {/* Objective */}
          <div className="space-y-2">
            <label className="text-sm text-gray-700">Write the objective</label>
            <textarea
              placeholder="Write the objective of an assignment"
              className="focus:border-transparent w-full rounded-md border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={objective}
              onChange={(e) => setObjective(e.target.value)}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-between gap-4 pt-4">
          <button className="rounded-md bg-gray-200 px-14 py-2 text-gray-700 transition-colors hover:bg-gray-300">
            Cancel
          </button>
          <button className="rounded-md bg-blue-600 px-14 py-2 text-white transition-colors hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
export default AssignmentForm;
