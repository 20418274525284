import useAxios from "axios-hooks";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { useEffect, useState } from "react";

function AssMilestoneRowForm({
  showMilestoneForm,
  setShowMilestoneForm,
  handleSave,
  data,
  edit = false,
  setEdit,
}) {
  const [status, setStatus] = useState("not_started");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assigned, setAssigned] = useState([]);

  const [{ data: stateMinisters }] = useAxios(
    {
      url: `/account/state-minister-users/`,
      method: "GET",
    },
    { useCache: false }
  );

  const submit = (e) => {
    e.preventDefault();
    const formData = {
      milestoneName: name,
      milestoneDescription: description,
      milestoneEndDate: endDate || null,
      assignedTo: assigned,
      status,
    };
    handleSave(formData, edit ? data?.id : null);
  };

  useEffect(() => {
    if (edit) {
      setName(data?.assignment_milestone_name || "");
      setDescription(data?.description || "");
      setEndDate(data?.end_date);
      setStatus(data?.status);
      setAssigned(data?.assigned_to || []);
    } else {
      setName("");
      setDescription("");
      setEndDate("");
      setStatus("not_started");
      setAssigned([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <form
      className={`${showMilestoneForm ? "relative" : "hidden"} mt-2 pb-14`}
      onSubmit={submit}
    >
      <div className="flex items-start justify-between gap-10 rounded-lg bg-white py-2 px-4 text-gray-700">
        {/* left side */}
        <div className="flex-grow">
          <div className="mt-4 flex items-center justify-between">
            <div>
              <h1>
                Name <span className="text-red-700">*</span>
              </h1>
              <p className="text-xs text-gray-500">Write a name</p>
            </div>
            <input
              id="milestoneName"
              type="text"
              placeholder="Milestone Name"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>Description</h1>
              <p className="text-xs text-gray-500">Short Description</p>
            </div>
            <textarea
              id="milestoneDescription"
              placeholder="Milestone Description"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setDescription(e.target.value)}
              value={description}
            />
          </div>
        </div>
        {/* Right side */}
        <div className=" flex-grow">
          <div className="mt-4 flex justify-between">
            <div>
              <p className="flex-grow lg:flex-grow-0">Responsible Party</p>
              <p className="text-xs text-gray-500">
                Responsible party / individual
              </p>
            </div>
            {assigned && (
              <MultiSelectDropdown
                key={stateMinisters}
                formFieldName={"Responsible Party"}
                options={stateMinisters || []}
                prompt="Select one or more responsible parties"
                name="assigned"
                selectedValues={assigned}
                onChange={(selected) => {
                  setAssigned(selected.map((item) => item?.profile?.user));
                }}
              />
            )}
          </div>
          <div className="mt-4 flex flex-grow items-center justify-between">
            <div>
              <h1>End Date</h1>
              <p className="text-xs text-gray-500">Type Date</p>
            </div>
            <input
              id="milestoneStartDate"
              type="date"
              placeholder="Start Date"
              className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
              onChange={(e) => setEndDate(e.target.value)}
              value={endDate}
            />
          </div>
        </div>
      </div>

      <div className="absolute right-3 bottom-0 flex items-center gap-5">
        <div
          className="flex cursor-pointer items-center gap-1 rounded-md bg-gray-500 py-1.5 px-3 text-white"
          onClick={() => {
            setEdit(null);
            setShowMilestoneForm(false);
          }}
        >
          Cancel
        </div>

        <button
          className=" flex items-center gap-1 rounded-md bg-[#485FE5] py-1.5 px-6 text-white"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  );
}
export default AssMilestoneRowForm;
