import useAxios from "axios-hooks";
import Error from "components/error";
import DateFields from "components/fields/DateFields";
import Loading from "components/loading";
import MultiSelectDropdown from "components/MultiSelectDropdown";
import { checkDate } from "helpers/utils";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

function ActivityModalForm({
  id,
  setOpen,
  edit = false,
  data,
  setEdit,
  handleSave,
  error,
  loading,
}) {
  const [status, setStatus] = useState("not_started");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [activityBudget, setActivityBudget] = useState("");
  const [activityDepartments, setActivityDepartments] = useState([]);

  const [{ data: departments }] = useAxios({
    url: "/account/departments/",
    method: "GET",
    params: {
      no_pagination: true,
    },
  });

  const submit = (e) => {
    e.preventDefault();
    checkDate(startDate, endDate) &&
      handleSave({
        activityName: name,
        activityDesc: description,
        activityStatus: status,
        activityStartDate: `${startDate.year}-${startDate.month}-${startDate.day}`,
        activityEndDate: `${endDate.year}-${endDate.month}-${endDate.day}`,
        activityBudget: activityBudget,
        activityDepartments: activityDepartments,
      });
  };

  useEffect(() => {
    if (edit && data) {
      setName(data?.activity_name || "");
      setDescription(data?.activity_description || "");
      setStartDate(
        data?.start_date
          ? {
              day: data?.start_date.substring(8, 10),
              month: data?.start_date.substring(5, 7),
              year: data?.start_date.substring(0, 4),
            }
          : { day: "", month: "", year: "" }
      );
      setEndDate(
        data?.end_date
          ? {
              day: data?.end_date.substring(8, 10),
              month: data?.end_date.substring(5, 7),
              year: data?.end_date.substring(0, 4),
            }
          : { day: "", month: "", year: "" }
      );
      setActivityBudget(data?.activity_budget || "");
      setStatus(data?.status || "not_started");
      setActivityDepartments(data?.departments || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <div
      id={id || "ActivityFormModal"}
      tabIndex="-1"
      className="fixed inset-0 z-50 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative rounded-lg bg-white pb-6 dark:bg-navy-800 md:min-w-[400px]">
        <button
          tabIndex="-1"
          className="absolute top-4 right-5"
          onClick={() => {
            setEdit(null);
            setOpen(false);
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>

        <div className="mt-12 flex min-h-[280px] min-w-[1200px] flex-col items-center px-6 text-start lg:px-8">
          <h1 className="self-start text-2xl font-semibold text-gray-700">
            {edit ? "Update" : "Add"} Activity
          </h1>
          {error && <Error error={error} />}
          {loading && <Loading />}
          {!loading && (
            <form className="relative mt-2 w-full pb-6" onSubmit={submit}>
              <div className="items-start justify-between gap-10 rounded-lg bg-white px-4 pt-2 pb-4 text-start text-gray-700 dark:bg-navy-800 dark:text-white md:flex">
                {/* left side */}
                <div className="flex-grow">
                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      <h1>
                        Name <span className="text-red-700">*</span>
                      </h1>
                      <p className="text-xs text-gray-500">Write a name</p>
                    </div>
                    <input
                      id="activityName"
                      type="text"
                      placeholder="Activity Name"
                      className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                    />
                  </div>
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1>Description</h1>
                      <p className="text-xs text-gray-500">Short Description</p>
                    </div>
                    <textarea
                      id="activityDescription"
                      placeholder="Activity Description"
                      className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1 className="">Budget</h1>
                      <p className="text-xs text-gray-500">Write a budget</p>
                    </div>
                    <input
                      id="activityBudget"
                      type="number"
                      pattern="[0-9]*"
                      placeholder="Activity Budget"
                      className="w-[55%] rounded-lg bg-gray-50 py-4 px-2 dark:bg-navy-700"
                      onChange={(e) => setActivityBudget(e.target.value)}
                      value={activityBudget}
                    />
                  </div>
                </div>
                {/* Right side */}
                <div className="flex-grow lg:min-w-[40%]">
                  <div className="mt-4 flex justify-between">
                    <p className="mt-2 w-[53%] lg:flex-grow-0">Committee</p>
                    <MultiSelectDropdown
                      key={departments}
                      formFieldName={"Committee"}
                      options={departments || []}
                      name="department_name"
                      selectedValues={
                        activityDepartments ? activityDepartments : []
                      }
                      onChange={(selected) => {
                        setActivityDepartments(selected.map((item) => item.id));
                      }}
                      prompt="Select one or more committees"
                    />
                  </div>
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1>
                        Start Date <span className="text-red-700">*</span>
                      </h1>
                      <p className="text-xs text-gray-500">Type Date</p>
                    </div>
                    <DateFields value={startDate} setValue={setStartDate} />
                  </div>
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1>
                        End Date <span className="text-red-700">*</span>
                      </h1>
                      <p className="text-xs text-gray-500">Type Date</p>
                    </div>
                    <DateFields value={endDate} setValue={setEndDate} />
                  </div>
                  {!checkDate(startDate, endDate) && (
                    <span className="text-xs text-red-500">
                      End date cannot be less than Start date
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-end gap-6">
                <button
                  className="-mb-3 rounded-md bg-gray-500 py-2 px-6 text-white"
                  type="button"
                  onClick={() => {
                    setEdit(null);
                    setOpen(false);
                  }}
                >
                  cancel
                </button>
                <button
                  className="-mb-3 rounded-md bg-brand-600 py-2 px-8 text-white"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
export default ActivityModalForm;
