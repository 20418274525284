/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useAxios from "axios-hooks";
import Error from "components/error";
import Loading from "components/loading";
import { useNavigate, useParams } from "react-router-dom";
import { columnsDataDeliverables } from "views/admin/ceo/variables/columnsData";
import ListsLayout from "views/admin/ceo/components/ListsLayout";
import ActivitiesComplexTable from "views/admin/tables/components/ActivitiesComplexTable";
import SearchIcon from "components/icons/SearchIcon";
import toast from "react-hot-toast";
import { IoCheckmark } from "react-icons/io5";
import DeliverableModalForm from "views/admin/expert/components/DeliverableModalForm";
import Paginate from "components/pagination";

function DeliverablesList() {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const actName = new URLSearchParams(window.location.search).get("milename");
  const routePage = new URLSearchParams(window.location.search).get("page");
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);

  const userPath = path.includes("ceo")
    ? "ceo"
    : path.includes("team-leader")
    ? "team-leader"
    : path.includes("ministry")
    ? "ministry"
    : path.includes("higher")
    ? "higher-official"
    : "expert";
  const params = useParams();
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedDel, setSelectedDel] = useState();
  const [error, setError] = useState();
  const link = path.includes("my-task")
    ? `/${userPath}/my-task/${params?.id}/milestones/${params?.mileId}/activities`
    : `/${userPath}/tasks/${params?.id}/milestones/${params?.mileId}/activities`;

  const [{ data: delData, loading: delLoad, error: delErr }, getMilestones] =
    useAxios(
      {
        url: `/main_app/deliverables/filtered_deliverable/`,
        params: {
          activity_id: params.actId,
          page: page || 1,
          search: search,
        },
        method: "GET",
      },
      { manual: true }
    );

  const [{ loading: delPostLoad, error: delPostErr }, postDel] = useAxios(
    {
      url: "/main_app/deliverables/",
      method: "POST",
    },
    { manual: true }
  );

  const handleDelSave = (data) => {
    const formdata = {
      deliverable_name: data.deliverableName,
      deliverable_description: data.deliverableDescription,
      target: data.target,
      measurement_unit: data.measurementUnit,
      start_date: data.startDate,
      end_date: data.endDate,
      status: data.status,
      activity: params.actId,
    };
    if (selectedDel !== null) {
      postDel({
        url: `/main_app/deliverables/${selectedDel.id}/`,
        method: "PUT",
        data: formdata,
      })
        .then(() => {
          toast("Deliverable updated successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          getMilestones({}, { useCache: false });
          setSelectedDel(null);
          setShowModal(false);
        })
        .catch((error) => {
          setError(delPostErr);
          console.log(error);
        });
    } else {
      postDel({
        data: formdata,
      })
        .then(() => {
          toast("Deliverable added successfully", {
            icon: <IoCheckmark />,
            style: { color: "green" },
          });
          getMilestones({}, { useCache: false });
          setShowModal(false);
        })
        .catch((error) => {
          setError(delPostErr);
          console.log(error);
        });
    }
  };

  const handleDelDelete = (id) => {
    if (!id) return;
    postDel({
      url: `/main_app/deliverables/${id}/`,
      method: "DELETE",
    })
      .then(() => {
        toast("Deliverable deleted successfully", {
          icon: <IoCheckmark />,
          style: { color: "green" },
        });
        getMilestones({}, { useCache: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (params.id) {
      getMilestones();
    }
  }, [params.id]);

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    getMilestones();
  }, [page]);

  useEffect(() => {
    if (selectedDel) {
      setShowModal(true);
      setError(null);
    }
  }, [selectedDel]);

  return (
    <ListsLayout
      title={actName || "Deliverables List"}
      breadcrumb={`/tasks/milestones/activities/deliverables`}
    >
      {delLoad && <Loading />}
      {!delLoad && (
        <div className="mt-5 flex-grow rounded-lg bg-[#F2F9FFCC] py-8 px-8 dark:bg-navy-700">
          {/* Activities */}
          <div className="mb-5 flex items-start justify-between ">
            <h1 className="text-2xl font-semibold text-black-900">
              Deliverables
            </h1>
            <div className="mt-2 flex flex-col items-end justify-end gap-2 md:flex-row md:items-center md:gap-0">
              <div className="flex items-center rounded-l-md bg-white dark:bg-navy-900">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className=" w-full py-1.5 px-4 focus:outline-none dark:bg-navy-900 dark:text-white dark:placeholder-gray-400"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {path.includes("expert") && (
                <>
                  {showModal && (
                    <DeliverableModalForm
                      data={selectedDel}
                      edit={selectedDel}
                      setEdit={setSelectedDel}
                      setOpen={(bool) => {
                        setSelectedDel(null);
                        setShowModal(bool);
                      }}
                      error={error}
                      handleSave={handleDelSave}
                      loading={delPostLoad}
                    />
                  )}
                  <button
                    className="rounded bg-[#485FE5] px-4 py-1.5 text-white md:rounded-none md:rounded-r-md"
                    onClick={() => {
                      setSelectedDel(null);
                      setError(null);
                      setShowModal(true);
                    }}
                  >
                    Add Deliverable
                  </button>
                </>
              )}
            </div>
          </div>
          {delErr && <Error error={delErr} />}

          {!delErr && (
            <ActivitiesComplexTable
              tableData={delData?.results?.deliverables || []}
              columnsData={columnsDataDeliverables}
              isExpert={path.includes("expert")}
              setSelected={setSelectedDel}
              handleDelete={handleDelDelete}
              handleDelDelete={handleDelDelete}
              refetch={getMilestones}
              link={link}
            />
          )}
          <Paginate
            count={delData?.count || 0}
            page={page}
            setPage={(p) => {
              navigate(`${link}/${params.actId}?milename=${actName}&page=${p}`);
            }}
            next={() => {
              if (!delData?.next) {
                return;
              }
              navigate(
                `${link}/${params.actId}?milename=${actName}&page=${page + 1}`
              );
            }}
            previous={() => {
              if (!delData?.previous) {
                return;
              }
              navigate(
                `${link}/${params.actId}?milename=${actName}&page=${page - 1}`
              );
            }}
          />
        </div>
      )}
    </ListsLayout>
  );
}

export default DeliverablesList;
