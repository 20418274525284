import StatusShow from "components/StatusShow";
import { FiX } from "react-icons/fi";

function MilestoneViewModal({ id, data }) {
  return (
    <div
      id={id || "MilestoneViewModal"}
      tabIndex="-1"
      className="fixed inset-0 z-50 hidden w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative rounded-lg bg-white pb-6 dark:bg-navy-800 md:min-w-[400px]">
        <button
          tabIndex="-1"
          className="absolute top-4 right-5"
          onClick={() => {
            window.document
              .getElementById(id || "MilestoneViewModal")
              .classList.replace("flex", "hidden");
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>

        <div className="mt-12 flex flex-col items-center px-6 text-center lg:px-12">
          <h1
            className={`max-w-[600px] text-start font-semibold text-gray-900 ${
              data?.milestone_name?.length > 20 ? "text-2xl" : "text-3xl"
            }`}
          >
            {data?.milestone_name || "Activity Name"}
          </h1>
          <div className="mt-8 mb-2 flex max-w-[500px] flex-wrap items-center gap-2 self-start">
            <p className="text-start text-lg font-bold">Description:</p>
            <p className="text-start text-base text-gray-700">
              {data?.milestone_description || "N/A"}
            </p>
          </div>

          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Delivery Date:</p>
            <p className="text-start text-gray-700">
              {data?.delivery_date?.substring(0, 10)}
            </p>
          </div>
          <div className="mt-3 mb-2 flex max-w-[500px] items-center justify-between gap-5 self-start">
            <p className="text-start text-lg font-bold">Status:</p>
            <StatusShow status={data?.status} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MilestoneViewModal;
