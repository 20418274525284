import Error from "components/error";
import DateFields from "components/fields/DateFields";
import Loading from "components/loading";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

function MilestoneModalForm({
  id,
  setOpen,
  edit = false,
  data,
  setEdit,
  handleSave,
  error,
  loading,
}) {
  const [status, setStatus] = useState("not_started");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [deliveryDate, setDeliveryDate] = useState({
    day: "",
    month: "",
    year: "",
  });

  const submit = (e) => {
    e.preventDefault();
    handleSave({
      milestoneName: name,
      milestoneDescription: description,
      milestoneDeliveryDate: `${deliveryDate.year}-${deliveryDate.month}-${deliveryDate.day}`,
      status,
    });
  };
  useEffect(() => {
    if (edit) {
      setName(data?.milestone_name);
      setDescription(data?.milestone_description);
      setDeliveryDate(
        data?.delivery_date
          ? {
              day: data?.delivery_date.substring(8, 10),
              month: data?.delivery_date.substring(5, 7),
              year: data?.delivery_date.substring(0, 4),
            }
          : { day: "", month: "", year: "" }
      );
      setStatus(data?.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  return (
    <div
      id={id || "MilestoneFormModal"}
      tabIndex="-1"
      className="fixed inset-0 z-50 flex w-full items-center justify-center overflow-y-scroll bg-gray-900/50 dark:bg-gray-900/80"
    >
      <div className="relative rounded-lg bg-white pb-6 dark:bg-navy-800 md:min-w-[400px]">
        <button
          tabIndex="-1"
          className="absolute top-4 right-5"
          onClick={() => {
            setEdit(null);
            setOpen();
          }}
        >
          <FiX className="mx-auto h-6 w-6 text-red-600" />
        </button>

        <div className="mt-12 flex min-h-[280px] flex-col items-center px-6 text-start lg:px-8 xl:min-w-[70rem]">
          <h1 className="self-start text-2xl font-semibold text-gray-700">
            {edit ? "Update" : "Add"} Milestone
          </h1>
          {error && <Error error={error} />}
          {loading && <Loading />}
          {!loading && (
            <form
              className="relative mt-2 min-h-[280px] min-w-full pb-14"
              onSubmit={submit}
            >
              <div className="flex items-start justify-between gap-10 rounded-lg bg-white py-2 px-4 text-gray-700">
                {/* left side */}
                <div className="flex-grow">
                  <div className="mt-4 flex items-center justify-between">
                    <div>
                      <h1 className="text-lg">
                        Milestone Name <span className="text-red-700">*</span>
                      </h1>
                      <p className="text-sm text-gray-500">Write a name</p>
                    </div>
                    <input
                      id="milestoneName"
                      type="text"
                      placeholder="Milestone Name"
                      className="w-[55%] rounded-lg bg-gray-50 py-4 px-3"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                    />
                  </div>
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1 className="text-lg">Milestone Description</h1>
                      <p className="text-sm text-gray-500">Short Description</p>
                    </div>
                    <textarea
                      id="milestoneDescription"
                      placeholder="Milestone Description"
                      className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </div>
                </div>
                {/* Right side */}
                <div className=" flex-grow">
                  <div className="mt-4 flex flex-grow items-center justify-between">
                    <div>
                      <h1 className="text-lg">
                        Delivery Date <span className="text-red-700">*</span>
                      </h1>
                      <p className="text-sm text-gray-500">Type Date</p>
                    </div>
                    <DateFields
                      value={deliveryDate}
                      setValue={setDeliveryDate}
                    />
                  </div>
                </div>
              </div>

              <div className="absolute right-3 bottom-0 flex items-center gap-5">
                <div
                  className="flex cursor-pointer items-center gap-1 rounded-md bg-gray-500 py-2 px-5 text-white"
                  type="button"
                  onClick={() => {
                    setEdit(null);
                    setOpen();
                  }}
                >
                  Cancel
                </div>

                <button
                  className=" flex items-center gap-1 rounded-md bg-[#485FE5] py-2 px-6 text-white"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
export default MilestoneModalForm;
