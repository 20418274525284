import ComplexTable from "./components/ComplexTable";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "axios-hooks";
import Loading from "components/loading";
import Error from "components/error";
import Paginate from "components/pagination";
import { TLRouteNameContext } from "layouts/teamLeader";
import AllProjects from "components/icons/AllProjects";
import SidebarModal from "components/popover/SidebarModal";
import ProjectStatus from "views/admin/ceo/components/ProjectStatus";
import { IoChevronBack } from "react-icons/io5";

const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Task Name",
    accessor: "project_name",
  },
  {
    Header: "Start Date",
    accessor: "start_date",
  },
  {
    Header: "End Date",
    accessor: "end_date",
  },

  {
    Header: "Priority",
    accessor: "project_priority",
  },

  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];

function ProjectsList() {
  const routePage = new URLSearchParams(window.location.search).get("page");
  const navigate = useNavigate();
  const [page, setPage] = useState(routePage ? parseInt(routePage) : 1);
  const [search, setSearch] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { setCurrentRoute, currentRoute } = useContext(TLRouteNameContext);
  const tabs = [{ title: "All Tasks", icon: <AllProjects /> }];

  const [{ data: projects, loading, error }] = useAxios(
    {
      url: "/main_app/projects/",
      params: {
        page: page || 1,
        search: search,
      },
      method: "GET",
    },
    { useCache: false }
  );

  useEffect(() => {
    if (routePage) {
      setPage(parseInt(routePage));
      // fetchProjects();
    } else {
      setPage(1);
    }
    return () => {};
  }, [routePage]);

  useEffect(() => {
    if (currentRoute !== "Projects List") {
      setCurrentRoute("Projects List");
    }
    return () => {};
    // eslint-disable-next-line
  }, [setCurrentRoute, currentRoute]);

  useEffect(() => {
    if (selectedProject) {
      setShowModal(true);
    }
  }, [selectedProject]);

  return (
    <div className="mt-5">
      {/* Details drawer */}
      <SidebarModal
        isOpen={showModal}
        setIsOpen={(bool) => {
          setShowModal(bool);
          setSelectedProject(null);
        }}
        content={<ProjectStatus project={selectedProject} />}
        closeBtn={
          <button
            className={`fixed top-8 left-[85%] z-50 rounded-full p-3 dark:bg-navy-700 md:left-[96%] md:top-6 4xl:top-[48%] 4xl:left-[66%] 4xl:bg-[#485FE5]`}
            onClick={() => {
              setShowModal(false);
              setSelectedProject(null);
            }}
          >
            <IoChevronBack
              className={`h-7 w-7 rotate-180 text-gray-800 4xl:h-10 4xl:w-10 4xl:text-white`}
            />
          </button>
        }
        bg={"bg-[#F5FAFF]"}
      />
      <div className="mb-3 flex-wrap items-center justify-between md:flex">
        <div className="my-3 w-fit border-b border-gray-200 text-center text-sm font-medium text-gray-500 dark:border-navy-700 dark:text-gray-400">
          <ul className="-mb-px flex w-fit flex-wrap gap-5 lg:gap-16">
            {tabs.map((item, index) => (
              <li className="flex w-fit items-center" key={index}>
                <p
                  className={`flex cursor-pointer items-center gap-3 rounded-t-lg border-b-2 p-4 hover:border-navy-300 hover:text-gray-600  dark:hover:text-gray-300 ${
                    0 === index
                      ? "border-blue-600 fill-blue-600 text-blue-600 dark:border-navy-500 dark:text-blue-500"
                      : "border-none fill-[#84818A]"
                  }`}
                >
                  {item.icon}
                  {item.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <input
          type="text"
          placeholder="Search"
          className="h-14 flex-grow rounded-lg border border-gray-200 px-3 text-gray-900 placeholder:text-gray-900 lg:max-w-[250px]"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {error && <Error error={error} />}
      {loading && <Loading />}

      <>
        {!error && !loading && (
          <ComplexTable
            columnsData={columnsDataComplex}
            tableData={projects?.results || []}
            setSelectedProject={setSelectedProject}
          />
        )}
        <Paginate
          count={projects?.count || 0}
          page={page}
          setPage={(p) => {
            navigate(`/team-leader/tasks?page=${p}`);
            setPage(p);
          }}
          next={() => {
            if (!projects?.next) {
              return;
            }
            navigate(`/team-leader/tasks?page=${page + 1}`);
            setPage(page + 1);
          }}
          previous={() => {
            if (!projects?.previous) {
              return;
            }
            navigate(`/team-leader/tasks?page=${page - 1}`);
            setPage(page - 1);
          }}
        />
      </>
    </div>
  );
}

export default ProjectsList;
