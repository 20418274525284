import DropdownControlled from "components/dropdown/DropdownControlled";
import DateFields from "components/fields/DateFields";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";

export const statusOptions = [
  { title: "not_started", color: "#C97A20" },
  // { title: "pending", color: "#C97A20" },
  { title: "ongoing", color: "#5E5ADB" },
  { title: "completed", color: "#38A06C" },
  // { title: "terminated", color: "#687182" },
];

function StatusDropdown({ button, pos, onSubmit, status }) {
  const [selectedStatus, setSelectedStatus] = useState("not_started");
  const [openWrapper, setOpenWrapper] = useState(false);
  const [startDate, setStartDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [endDate, setEndDate] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [budget, setBudget] = useState("");
  const [file, setFile] = useState(null);
  const [statusMemo, setStatusMemo] = useState("");
  const formId = `status-form-${Math.random() * 100000000000}`;

  const handleCompleteSubmit = () => {
    const onSubmitData = {
      status: selectedStatus,
      startDate: `${startDate.year}-${startDate.month}-${startDate.day}`,
      endDate: `${endDate.year}-${endDate.month}-${endDate.day}`,
      budget,
      file,
      status_memo: statusMemo,
    };
    setOpenWrapper(false);
    onSubmit(onSubmitData);
  };

  const handleStatusChange = (value) => {
    setOpenWrapper(false);
    onSubmit({ status: value, status_memo: statusMemo });
  };

  const clearForm = () => {
    setStartDate("");
    setEndDate("");
    setBudget("");
    setFile(null);
    setSelectedStatus("not_started");
    setStatusMemo("");
  };

  useEffect(() => {
    if (status) {
      setSelectedStatus(status);
    }
    return () => {
      clearForm();
    };
  }, [status]);

  return (
    <DropdownControlled
      openWrapper={openWrapper}
      setOpenWrapper={(state) => {
        setOpenWrapper(state);
        clearForm();
      }}
      id="status-dropdown"
      classNames={`pt-2 top-8 ${
        pos ? pos : "-left-18"
      } w-max shadow-xl rounded-lg`}
      button={button ? button : <FaChevronDown className="cursor-pointer" />}
      children={
        <>
          <div className="flex min-w-[250px] gap-8 rounded-t-xl bg-white p-4 text-sm">
            <div className="flex-grow">
              <h1 className="mb-3 text-start font-semibold">Change status</h1>
              <ul className="flex flex-col gap-1">
                {statusOptions.map((status, index) => (
                  <li
                    key={index}
                    className={`cursor-pointer ${
                      selectedStatus === status.title
                        ? "flex w-full items-center justify-between rounded-lg bg-[#5E5ADB] px-2 text-white"
                        : "hover:bg-gray-50"
                    }`}
                    onClick={() => setSelectedStatus(status.title)}
                  >
                    <div className="my-2 flex  items-center gap-2 ">
                      <div
                        className={`ml-1 h-3 w-3 rounded-sm `}
                        style={{ background: status.color }}
                      />
                      <p className="mr-2">{status.title.toUpperCase()}</p>
                    </div>
                  </li>
                ))}
              </ul>
              {selectedStatus !== "completed" && (
                <div className="mt-4 flex w-full flex-grow flex-col text-start">
                  <div>
                    <h1>Memo :</h1>
                    <p className="text-xs text-gray-500">Type Status Memo</p>
                  </div>
                  <input
                    id="statusMemo"
                    type="text"
                    placeholder="Status Memo"
                    className="mt-1 rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setStatusMemo(e.target.value)}
                    value={statusMemo}
                  />
                </div>
              )}
            </div>
            {selectedStatus === "completed" && (
              <form
                id={formId}
                className="mb-2 text-start"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCompleteSubmit();
                }}
              >
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      Actual Start Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <DateFields value={startDate} setValue={setStartDate} />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>
                      Actual End Date <span className="text-red-700">*</span>
                    </h1>
                    <p className="text-xs text-gray-500">Type Date</p>
                  </div>
                  <DateFields value={endDate} setValue={setEndDate} />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Actual Cost</h1>
                    <p className="text-xs text-gray-500">Type Actual Cost</p>
                  </div>
                  <input
                    id="statusBudget"
                    type="number"
                    placeholder="Actual Cost"
                    className="w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setBudget(e.target.value)}
                    value={budget}
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div>
                    <h1>Memo:</h1>
                    <p className="text-xs text-gray-500">Type Status Memo</p>
                  </div>
                  <input
                    id="statusMemo"
                    type="text"
                    placeholder="Status Memo"
                    className="mt-1 rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setStatusMemo(e.target.value)}
                    value={statusMemo}
                  />
                </div>
                <div className="mt-4 flex flex-grow items-center justify-between">
                  <div className="flex-grow">
                    <h1>File</h1>
                    <p className="text-xs text-gray-500">Type File</p>
                  </div>
                  <input
                    id="statusFile"
                    type="file"
                    placeholder="File"
                    className="w-fit max-w-[55%] rounded-lg bg-gray-50 py-4 px-2"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                {/*  */}
                <div className="mt-5 flex w-full items-center justify-end gap-2 self-end rounded-b-md bg-white pr-2">
                  <div
                    className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800"
                    onClick={() => {
                      clearForm();
                      setOpenWrapper(false);
                    }}
                  >
                    Cancel
                  </div>
                  <button
                    className="cursor-pointer rounded-lg p-2 px-6 text-[#5E5ADB] hover:bg-navy-50"
                    type="submit"
                  >
                    Apply
                  </button>
                </div>
              </form>
            )}
          </div>

          {selectedStatus !== "completed" && (
            <div className="flex w-full items-center justify-end gap-2 self-end rounded-b-md bg-white pr-2 pb-2">
              <div
                className="cursor-pointer rounded-lg p-2 px-4 hover:bg-gray-50 dark:text-gray-800"
                onClick={() => {
                  clearForm();
                  setOpenWrapper(false);
                }}
              >
                Cancel
              </div>
              <div
                className="cursor-pointer rounded-lg p-2 px-6 text-[#5E5ADB] hover:bg-navy-50"
                onClick={() => {
                  setOpenWrapper(false);
                  handleStatusChange(selectedStatus);
                  clearForm();
                }}
              >
                Apply
              </div>
            </div>
          )}
        </>
      }
    />
  );
}
export default StatusDropdown;
