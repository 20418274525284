function StatusBadge({ status }) {
  return (
    <div
      className="mt-2 ml-[4.5rem] flex w-fit items-center  gap-2 rounded-md px-3 py-0.5"
      style={{
        backgroundColor: status === "approved" ? "#E1FCEF" : "#FFEDEF",
      }}
    >
      <div
        className={`h-2 w-2 ${
          status === "approved" ? "bg-green-500" : "bg-red-800"
        }`}
      />
      <p
        className={`text-sm font-[400] capitalize ${
          status === "approved" ? "text-green-800" : "text-red-800"
        }`}
      >
        {status}
      </p>
    </div>
  );
}
export default StatusBadge;
