import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import ActivityTableRow from "views/admin/tables/components/ActivityTableRow";

const ActivitiesComplexTable = ({
  columnsData,
  tableData,
  setSelected,
  handleDelete,
  handleDelDelete,
  refetch,
  isExpert,
  link,
}) => {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: data.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  const deliverables = page.filter(
    (item) => item.original.parent_deliverable === null
  );

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto shadow"}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table
          {...getTableProps()}
          className="w-full overflow-visible p-4 font-poppins"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                <th className="ml-5 border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"></th>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                  >
                    <p className="max-w-[500px] pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {deliverables.map((row, index) => {
              prepareRow(row);
              return (
                <ActivityTableRow
                  row={row}
                  rowProps={row.getRowProps()}
                  setSelected={setSelected}
                  handleDelete={handleDelete}
                  handleDelDelete={handleDelDelete}
                  isExpert={isExpert}
                  link={link}
                  index={index}
                  refetch={refetch}
                  key={index}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ActivitiesComplexTable;
