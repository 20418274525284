import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/dropdown/ShadcnDropdown";
import { statusOptions } from "components/dropdown/StatusDropdown";
import StatusDropdownDeliverable from "components/dropdown/StatusDropdownDeliverable";
import DelViewModal from "components/popover/DelViewModal";
import { reverseDateString } from "helpers/utils";
import { useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import DeleteModal from "views/admin/expert/components/DeleteModal";

function DeliverableTableRow({
  child,
  i,
  isExpert,
  link,
  handleDelDelete,
  setSelected,
  handleStatusUpdate,
}) {
  const navigate = useNavigate();
  const [viewDel, setViewDel] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const parentRef = useRef(null);

  return (
    <tr className="bg-[#f8f8f8]">
      <td
        colSpan={2}
        className="border-b px-0 pt-[40px] pb-[20px] sm:text-[14px]"
      />
      <td className="border-b">
        <div className="flex max-w-[350px] items-center gap-2 truncate">
          <p
            className="max-w-[250px] cursor-pointer truncate pl-3 text-sm text-navy-900 dark:text-white 2xl:max-w-[420px]"
            onClick={() => {
              isExpert
                ? setViewDel(true)
                : navigate(
                    `${link}/${child.activity}/deliverables/${child.id}`
                  );
            }}
          >
            {child?.deliverable_name}
          </p>
        </div>
      </td>
      <td className="border-b">
        <p className="mr-2 w-[102px] rounded-2xl bg-[#deecfa] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
          {child.start_date?.length > 10
            ? reverseDateString(child.start_date.slice(0, 10))
            : reverseDateString(child.start_date)}
        </p>
      </td>
      <td className="border-b">
        <p className="mr-2 w-[102px] rounded-2xl bg-[#deecfa] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
          {child.end_date?.length > 10
            ? reverseDateString(child.end_date.slice(0, 10))
            : reverseDateString(child.end_date)}
        </p>
      </td>

      <td className="relative border-b">
        <div className="parent w-fit pr-2" ref={parentRef}>
          <div
            className={`flex items-center gap-1 ${
              child.status !== "completed" ? "cursor-pointer" : ""
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="w-fit rounded-full">
              <div
                className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                style={{
                  background:
                    statusOptions.find((item) => item.title === child.status)
                      .color + "40",
                }}
              >
                <div
                  className="h-2 w-2 rounded-sm"
                  style={{
                    background: statusOptions.find(
                      (item) => item.title === child.status
                    ).color,
                  }}
                />
                <p
                  className="text-sm"
                  style={{
                    color: statusOptions.find(
                      (item) => item.title === child.status
                    ).color,
                  }}
                >
                  {child.status.toUpperCase()}
                </p>
              </div>
            </div>
            {isExpert && child.status !== "completed" && (
              <FaChevronDown size={10} />
            )}
          </div>
          {isExpert && child.status !== "completed" && (
            <StatusDropdownDeliverable
              isOpen={isOpen}
              parentRef={parentRef}
              setIsOpen={setIsOpen}
              onSubmit={handleStatusUpdate}
            />
          )}
        </div>
      </td>

      <td className="border-b">
        {viewDel && (
          <DelViewModal
            id={"deliverable" + child.id}
            data={child}
            setOpen={setViewDel}
          />
        )}
        {isExpert ? (
          <div className="flex items-center gap-3 text-gray-800 dark:text-white">
            <DeleteModal
              id={"deliverableItem" + child.id}
              handleConfirm={() => {
                handleDelDelete(child.id);
                window.document
                  .getElementById("deliverableItem" + child.id)
                  .classList.replace("flex", "hidden");
              }}
              handleCancel={() => {
                window.document
                  .getElementById("deliverableItem" + child.id)
                  .classList.replace("flex", "hidden");
              }}
            />
            <button
              onClick={() => {
                navigate(`${link}/${child.activity}/deliverables/${child.id}`);
              }}
              className="max-w-[100px] truncate rounded-lg border border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white 2xl:max-w-full"
            >
              Open
            </button>
            <DropdownMenu>
              <DropdownMenuTrigger className="outline-none focus:outline-none">
                <BsThreeDotsVertical className="cursor-pointer" />
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem
                  className="cursor-pointer rounded-md hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white"
                  onClick={() => {
                    setViewDel(true);
                  }}
                >
                  View
                </DropdownMenuItem>
                {child.status !== "completed" && (
                  <DropdownMenuItem
                    className="cursor-pointer rounded-md hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white"
                    onClick={() => {
                      setSelected(child);
                    }}
                  >
                    Edit
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  className="cursor-pointer rounded-md hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white"
                  onClick={() => {
                    window.document
                      .getElementById("deliverableItem" + child.id)
                      .classList.replace("hidden", "flex");
                  }}
                >
                  Delete
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        ) : (
          <div className="flex w-full items-center gap-2">
            <Link
              to={`${link}/${child.activity}/deliverables/${child.id}`}
              className="rounded-lg border border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white"
            >
              Open
            </Link>
            <button
              className="rounded-lg border border-yellow-500 px-2 py-0.5 text-sm font-[400] hover:bg-yellow-500 hover:text-white dark:hover:bg-yellow-500 dark:hover:text-white"
              onClick={() => {
                setViewDel(true);
              }}
            >
              view
            </button>
          </div>
        )}
      </td>
    </tr>
  );
}
export default DeliverableTableRow;
