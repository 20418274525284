import React, { createContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "./sidebar";
import { useWindowSize } from "@uidotdev/usehooks";
import { FaTasks } from "react-icons/fa";
import ProjectDashboard from "views/admin/HO/ProjectDashboard";
import TaskDetails from "views/admin/ceo/TaskDetails";
import { higherOfficialRoutes } from "routes";
import ActivitiesList from "views/admin/ceo/ActivitiesList";
import DeliverableDetails from "views/admin/ceo/DeliverableDetails";
import DeliverablesList from "views/admin/ceo/DeliverablesList";

export const HoRouteNameContext = createContext(null);

export default function HOLayout(props) {
  const { ...rest } = props;
  const size = useWindowSize();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);
  const [currentRoute, setCurrentRoute] = React.useState(
    "Higher offical Dashboard"
  );

  React.useEffect(() => {
    if (size?.width < 1200) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [size]);

  React.useEffect(() => {
    getActiveRoute(higherOfficialRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(
          routes[i].layout + "/" + routes[i].path
        ) !== -1
      ) {
        setCurrentRoute(routes[i].name);
      }
    }
    return activeRoute;
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/higher-official") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <HoRouteNameContext.Provider value={{ currentRoute, setCurrentRoute }}>
      <div className="flex h-full w-full">
        <Sidebar
          open={open}
          onClose={() => setOpen(false)}
          setShowModal={setShowModal}
        />
        {/* Navbar & Main Content */}
        <div className="h-full w-full bg-white dark:!bg-navy-900">
          {/* Main Content */}
          <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
          >
            {/* Routes */}
            <div className="h-full">
              <Navbar
                onOpenSidenav={() => setOpen(true)}
                logoText={"MoLS Project Management System"}
                brandText={currentRoute}
                secondary={getActiveNavbar(higherOfficialRoutes)}
                {...rest}
              />
              <div className="mx-auto mb-auto h-full min-h-[84vh] p-2 pt-5 md:pr-2">
                <Routes>
                  {getRoutes([
                    ...higherOfficialRoutes,
                    {
                      name: "Project 1",
                      layout: "/higher-official",
                      icon: <FaTasks extra="h-6 w-6 fill-white" />,
                      path: "projects/:id/dashboard",
                      component: <ProjectDashboard />,
                    },
                    {
                      name: "Project Details",
                      layout: "/higher-official",
                      icon: <FaTasks extra="h-6 w-6 fill-white" />,
                      path: "tasks/:id",
                      component: <TaskDetails context={HoRouteNameContext} />,
                    },
                    {
                      name: "Activities List",
                      layout: "/higher-official",
                      path: "tasks/:id/milestones/:mileId",
                      component: <ActivitiesList />,
                    },
                    {
                      name: "Deliverables List",
                      layout: "/higher-official",
                      path: "tasks/:id/milestones/:mileId/activities/:actId",
                      component: <DeliverablesList />,
                    },
                    {
                      name: "Deliverables Details",
                      layout: "/higher-official",
                      path: "tasks/:id/milestones/:mileId/activities/:actId/deliverables/:delId",
                      component: <DeliverableDetails />,
                    },
                  ])}

                  <Route
                    path="/"
                    element={<Navigate to="/higher-official/home" replace />}
                  />
                </Routes>
              </div>
              <div className="p-7">{/* <Footer /> */}</div>
            </div>
          </main>
        </div>
      </div>
    </HoRouteNameContext.Provider>
  );
}
