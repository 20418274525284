import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { statusOptions } from "components/dropdown/StatusDropdown";
import DeleteModal from "views/admin/expert/components/DeleteModal";
import MilestoneViewModal from "components/popover/MilestoneViewModal";
import ActivityViewModal from "components/popover/ActivityViewModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/dropdown/ShadcnDropdown";
import { reverseDateString } from "helpers/utils";

const MileComplexTable = ({
  columnsData,
  tableData,
  setSelected,
  handleDelete,
  isExpert,
  link,
  name,
}) => {
  const navigate = useNavigate();

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [viewActivity, setViewActivity] = useState("");

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: data.length },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto shadow"}>
      <div className="mt-2 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full p-4 font-poppins">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="ml-5 max-w-fit border-b border-gray-200 bg-[#F5FAFF] p-5 pl-3 pb-[10px] text-start dark:!border-navy-900 dark:bg-navy-900"
                    style={{
                      width: column.width, // Apply custom width
                      minWidth: column.minWidth, // Apply min width
                      maxWidth: column.maxWidth, // Apply max width
                    }}
                  >
                    <p className="pb-2 text-xs tracking-wide text-gray-700 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, i) => {
                    let data = "";
                    if (cell.column.Header === "NO") {
                      data = (
                        <p className="w-fit pl-3 text-sm text-navy-900 dark:text-white">
                          {Number(index + 1).toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </p>
                      );
                    } else if (cell.column.Header === "Name") {
                      data = (
                        <p
                          className="w-fit max-w-[250px] cursor-pointer truncate pl-3 text-sm text-navy-900 dark:text-white"
                          onClick={() => {
                            if (isExpert) {
                              window.document
                                .getElementById(
                                  `${"milestone" + cell.row.original.id}` ||
                                    "MilestoneViewModal"
                                )
                                .classList.replace("hidden", "flex");
                              return;
                            }
                            navigate(
                              `${link}/${cell.row.original.id}?milename=${cell.row.original.milestone_name}`
                            );
                          }}
                        >
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "Status") {
                      data = (
                        <div className="w-fit rounded-full">
                          <div
                            className="flex items-center gap-2 rounded-lg py-0.5 px-3"
                            style={{
                              background:
                                statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color + "40",
                            }}
                          >
                            <div
                              className="h-2 w-2 rounded-sm "
                              style={{
                                background: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            />
                            <p
                              className="text-sm "
                              style={{
                                color: statusOptions.find(
                                  (item) => item.title === cell.value
                                ).color,
                              }}
                            >
                              {cell.value.toUpperCase()}
                            </p>
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === "Start Date") {
                      data = (
                        <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {cell.value?.length > 10
                            ? reverseDateString(cell.value.slice(0, 10))
                            : reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "End Date") {
                      data = (
                        <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {cell.value?.length > 10
                            ? reverseDateString(cell.value.slice(0, 10))
                            : reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "Delivery Date") {
                      data = (
                        <p className="mr-2 w-[102px] rounded-2xl bg-[#F5F6F7] py-1 px-3 text-sm text-navy-900 dark:bg-navy-900 dark:text-white md:w-fit">
                          {cell.value?.length > 10
                            ? reverseDateString(cell.value.slice(0, 10))
                            : reverseDateString(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "Action") {
                      data = (
                        <>
                          {viewActivity === "activity" + row.original.id && (
                            <ActivityViewModal
                              id={"activity" + row.original.id}
                              data={row.original}
                              setOpen={() => setViewActivity("")}
                            />
                          )}
                          <DeleteModal
                            id={"project" + cell.row.original.id}
                            handleConfirm={() => {
                              handleDelete(cell.row.original.id);
                              window.document
                                .getElementById(
                                  "project" + cell.row.original.id
                                )
                                .classList.replace("flex", "hidden");
                            }}
                            handleCancel={() => {
                              window.document
                                .getElementById(
                                  "project" + cell.row.original.id
                                )
                                .classList.replace("flex", "hidden");
                            }}
                          />
                          {isExpert && (
                            <DeleteModal
                              id={"project" + cell.row.original.id}
                              handleConfirm={() => {
                                handleDelete(cell.row.original.id);
                                window.document
                                  .getElementById(
                                    "project" + cell.row.original.id
                                  )
                                  .classList.replace("flex", "hidden");
                              }}
                              handleCancel={() => {
                                window.document
                                  .getElementById(
                                    "project" + cell.row.original.id
                                  )
                                  .classList.replace("flex", "hidden");
                              }}
                            />
                          )}
                          <div className="flex items-center gap-2">
                            <Link
                              to={`${link}/${cell.row.original.id}?milename=${
                                cell.row.original.milestone_name ||
                                cell.row.original.activity_name
                              }`}
                              className="rounded-lg border  border-brand-500 px-2 py-0.5 text-center text-sm font-[400] hover:bg-brand-500 hover:text-white dark:hover:bg-brand-500 dark:hover:text-white"
                            >
                              {name === "activity_name"
                                ? "Deliverables"
                                : " Activities"}
                            </Link>
                            <DropdownMenu>
                              <DropdownMenuTrigger className="outline-none focus:outline-none">
                                <BsThreeDotsVertical className="cursor-pointer" />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <DropdownMenuItem
                                  className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                  onClick={() => {
                                    if (name === "activity_name") {
                                      setViewActivity(
                                        "activity" + cell.row.original.id
                                      );
                                      return;
                                    }
                                    window.document
                                      .getElementById(
                                        `${
                                          "milestone" + cell.row.original.id
                                        }` || "MilestoneViewModal"
                                      )
                                      .classList.replace("hidden", "flex");
                                  }}
                                >
                                  View
                                </DropdownMenuItem>
                                {isExpert && (
                                  <DropdownMenuItem
                                    className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                    onClick={() => {
                                      setSelected(cell.row.original);
                                    }}
                                  >
                                    Edit
                                  </DropdownMenuItem>
                                )}
                                {isExpert && (
                                  <DropdownMenuItem
                                    className="cursor-pointer rounded-md hover:bg-blueSecondary hover:text-white dark:hover:text-white"
                                    onClick={() => {
                                      window.document
                                        .getElementById(
                                          "project" + cell.row.original.id
                                        )
                                        .classList.replace("hidden", "flex");
                                    }}
                                  >
                                    Delete
                                  </DropdownMenuItem>
                                )}
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div>
                        </>
                      );
                    }
                    return (
                      <td
                        className="border-b px-0 pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={i}
                        style={{
                          width: cell.column.width, // Apply custom width
                          minWidth: cell.column.minWidth, // Apply min width
                          maxWidth: cell.column.maxWidth, // Apply max width
                        }}
                      >
                        <MilestoneViewModal
                          id={"milestone" + cell.row.original.id}
                          data={cell.row.original}
                        />
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default MileComplexTable;
